import _accessScope from "./access-scope";
import _apiPermission from "./api-permission";
import _applicationCharge from "./application-charge";
import _applicationCredit from "./application-credit";
import _article from "./article";
import _asset from "./asset";
import _balance from "./balance";
import _blog from "./blog";
import _cancellationRequest from "./cancellation-request";
import _carrierService from "./carrier-service";
import _checkout from "./checkout";
import _collect from "./collect";
import _collection from "./collection";
import _collectionListing from "./collection-listing";
import _comment from "./comment";
import _country from "./country";
import _currency from "./currency";
import _customCollection from "./custom-collection";
import _customer from "./customer";
import _customerAddress from "./customer-address";
import _customerSavedSearch from "./customer-saved-search";
import _deprecatedApiCall from "./deprecated-api-call";
import _discountCode from "./discount-code";
import _discountCodeCreationJob from "./discount-code-creation-job";
import _dispute from "./dispute";
import _disputeEvidence from "./dispute-evidence";
import _disputeFileUpload from "./dispute-file-upload";
import _draftOrder from "./draft-order";
import _event from "./event";
import _fulfillment from "./fulfillment";
import _fulfillmentEvent from "./fulfillment-event";
import _fulfillmentOrder from "./fulfillment-order";
import _fulfillmentRequest from "./fulfillment-request";
import _fulfillmentService from "./fulfillment-service";
import _giftCard from "./gift-card";
import _giftCardAdjustment from "./gift-card-adjustment";
import _inventoryItem from "./inventory-item";
import _inventoryLevel from "./inventory-level";
import _location from "./location";
import _marketingEvent from "./marketing-event";
import _metafield from "./metafield";
import _order from "./order";
import _orderRisk from "./order-risk";
import _page from "./page";
import _payment from "./payment";
import _payout from "./payout";
import _policy from "./policy";
import _priceRule from "./price-rule";
import _product from "./product";
import _productImage from "./product-image";
import _productListing from "./product-listing";
import _productResourceFeedback from "./product-resource-feedback";
import _productVariant from "./product-variant";
import _province from "./province";
import _recurringApplicationCharge from "./recurring-application-charge";
import _redirect from "./redirect";
import _refund from "./refund";
import _report from "./report";
import _resourceFeedback from "./resource-feedback";
import _scriptTag from "./script-tag";
import _shippingZone from "./shipping-zone";
import _shop from "./shop";
import _smartCollection from "./smart-collection";
import _storefrontAccessToken from "./storefront-access-token";
import _tenderTransaction from "./tender-transaction";
import _theme from "./theme";
import _transaction from "./transaction";
import _usageCharge from "./usage-charge";
import _user from "./user";
import _webhook from "./webhook";
var exports = {};
const map = {
  accessScope: () => _accessScope,
  apiPermission: () => _apiPermission,
  applicationCharge: () => _applicationCharge,
  applicationCredit: () => _applicationCredit,
  article: () => _article,
  asset: () => _asset,
  balance: () => _balance,
  blog: () => _blog,
  cancellationRequest: () => _cancellationRequest,
  carrierService: () => _carrierService,
  checkout: () => _checkout,
  collect: () => _collect,
  collection: () => _collection,
  collectionListing: () => _collectionListing,
  comment: () => _comment,
  country: () => _country,
  currency: () => _currency,
  customCollection: () => _customCollection,
  customer: () => _customer,
  customerAddress: () => _customerAddress,
  customerSavedSearch: () => _customerSavedSearch,
  deprecatedApiCall: () => _deprecatedApiCall,
  discountCode: () => _discountCode,
  discountCodeCreationJob: () => _discountCodeCreationJob,
  dispute: () => _dispute,
  disputeEvidence: () => _disputeEvidence,
  disputeFileUpload: () => _disputeFileUpload,
  draftOrder: () => _draftOrder,
  event: () => _event,
  fulfillment: () => _fulfillment,
  fulfillmentEvent: () => _fulfillmentEvent,
  fulfillmentOrder: () => _fulfillmentOrder,
  fulfillmentRequest: () => _fulfillmentRequest,
  fulfillmentService: () => _fulfillmentService,
  giftCard: () => _giftCard,
  giftCardAdjustment: () => _giftCardAdjustment,
  inventoryItem: () => _inventoryItem,
  inventoryLevel: () => _inventoryLevel,
  location: () => _location,
  marketingEvent: () => _marketingEvent,
  metafield: () => _metafield,
  order: () => _order,
  orderRisk: () => _orderRisk,
  page: () => _page,
  payment: () => _payment,
  payout: () => _payout,
  policy: () => _policy,
  priceRule: () => _priceRule,
  product: () => _product,
  productImage: () => _productImage,
  productListing: () => _productListing,
  productResourceFeedback: () => _productResourceFeedback,
  productVariant: () => _productVariant,
  province: () => _province,
  recurringApplicationCharge: () => _recurringApplicationCharge,
  redirect: () => _redirect,
  refund: () => _refund,
  report: () => _report,
  resourceFeedback: () => _resourceFeedback,
  scriptTag: () => _scriptTag,
  shippingZone: () => _shippingZone,
  shop: () => _shop,
  smartCollection: () => _smartCollection,
  storefrontAccessToken: () => _storefrontAccessToken,
  tenderTransaction: () => _tenderTransaction,
  theme: () => _theme,
  transaction: () => _transaction,
  usageCharge: () => _usageCharge,
  user: () => _user,
  webhook: () => _webhook
};

/**
 * Registers resources on the `Shopify` class.
 *
 * @param {Shopify} Shopify The `Shopify` class
 * @private
 */
function registerAll(Shopify) {
  Object.entries(map).forEach(([resourceName, importResource]) => {
    Object.defineProperty(Shopify.prototype, resourceName, {
      configurable: true,
      get: function get() {
        const resource = importResource();
        return Object.defineProperty(this, resourceName, {
          value: new resource(this)
        })[resourceName];
      },
      set: function set(value) {
        Object.defineProperty(this, resourceName, {
          value
        });
      }
    });
  });
}
exports = {
  registerAll
};
export default exports;