import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import * as _pick2 from "lodash/pick";
var _pick = _pick2;
try {
  if ("default" in _pick2) _pick = _pick2.default;
} catch (e) {}
import _base from "../mixins/base";
var exports = {};
const assign = _assign;
const pick = _pick;
const base = _base;

/**
 * Creates a Shop instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function Shop(shopify) {
  this.shopify = shopify;
  this.name = this.key = "shop";
}
assign(Shop.prototype, pick(base, "buildUrl"));

/**
 * Gets the configuration of the shop.
 *
 * @param {Object} [params] Query parameters
 * @return {Promise} Promise that resolves with the result
 * @public
 */
Shop.prototype.get = function get(params) {
  const url = this.buildUrl(undefined, params);
  return this.shopify.request(url, "GET", this.key);
};
exports = Shop;
export default exports;