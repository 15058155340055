import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import _baseChild from "../mixins/base-child";
var exports = {};
const assign = _assign;
const baseChild = _baseChild;

/**
 * Creates a ProductImage instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function ProductImage(shopify) {
  this.shopify = shopify;
  this.parentName = "products";
  this.name = "images";
  this.key = "image";
}
assign(ProductImage.prototype, baseChild);
exports = ProductImage;
export default exports;