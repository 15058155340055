import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import * as _omit2 from "lodash/omit";
var _omit = _omit2;
try {
  if ("default" in _omit2) _omit = _omit2.default;
} catch (e) {}
import _base from "../mixins/base";
var exports = {};
const assign = _assign;
const omit = _omit;
const base = _base;

/**
 * Creates a ResourceFeedback instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function ResourceFeedback(shopify) {
  this.shopify = shopify;
  this.name = "resource_feedback";
  this.key = "resource_feedback";
}
assign(ResourceFeedback.prototype, omit(base, ["count", "delete", "get", "update"]));
exports = ResourceFeedback;
export default exports;