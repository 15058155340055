import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import * as _omit2 from "lodash/omit";
var _omit = _omit2;
try {
  if ("default" in _omit2) _omit = _omit2.default;
} catch (e) {}
import _baseChild from "../mixins/base-child";
var exports = {};
const assign = _assign;
const omit = _omit;
const baseChild = _baseChild;

/**
 * Creates a Payment instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function Payment(shopify) {
  this.shopify = shopify;
  this.parentName = "checkouts";
  this.name = "payments";
  this.key = "payment";
}
assign(Payment.prototype, omit(baseChild, ["delete", "update"]));
exports = Payment;
export default exports;