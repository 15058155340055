import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import * as _qs2 from "qs";
var _qs = _qs2;
try {
  if ("default" in _qs2) _qs = _qs2.default;
} catch (e) {}
import _baseChild from "../mixins/base-child";
import _base from "../mixins/base";
var exports = {};
const assign = _assign;
const qs = _qs;
const baseChild = _baseChild;
const base = _base;

/**
 * Creates an Article instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function Article(shopify) {
  this.shopify = shopify;
  this.parentName = "blogs";
  this.name = "articles";
  this.key = "article";
}
assign(Article.prototype, baseChild);

/**
 * Gets a list of all the authors of articles.
 *
 * @return {Promise} Promise that resolves with the result
 * @public
 */
Article.prototype.authors = function authors() {
  const key = "authors";
  const url = base.buildUrl.call(this, key);
  return this.shopify.request(url, "GET", key);
};

/**
 * Gets a list of all the tags of articles.
 *
 * @param {Number} [blogId] Blog ID
 * @param {Object} [params] Query parameters
 * @return {Promise} Promise that resolves with the result
 * @public
 */
Article.prototype.tags = function tags(blogId, params) {
  let pathname = "/admin";
  if (!params && typeof blogId === "object") {
    params = blogId;
    blogId = undefined;
  }
  if (blogId || blogId === 0) pathname += `/blogs/${blogId}`;
  pathname += `/${this.name}/tags.json`;
  const url = {
    pathname
  };
  if (params) {
    url.search = "?" + qs.stringify(params, {
      arrayFormat: "brackets"
    });
  }
  return this.shopify.request(assign(url, this.shopify.baseUrl), "GET", "tags");
};
exports = Article;
export default exports;