import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import * as _omit2 from "lodash/omit";
var _omit = _omit2;
try {
  if ("default" in _omit2) _omit = _omit2.default;
} catch (e) {}
import _base from "../mixins/base";
var exports = {};
const assign = _assign;
const omit = _omit;
const base = _base;

/**
 * Creates an Event instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function Event(shopify) {
  this.shopify = shopify;
  this.name = "events";
  this.key = "event";
}
assign(Event.prototype, omit(base, ["create", "delete", "update"]));
exports = Event;
export default exports;