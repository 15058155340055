import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import * as _pick2 from "lodash/pick";
var _pick = _pick2;
try {
  if ("default" in _pick2) _pick = _pick2.default;
} catch (e) {}
import _shopifyPaymentsChild from "../mixins/shopify-payments-child";
import _baseChild from "../mixins/base-child";
var exports = {};
const assign = _assign;
const pick = _pick;
const shopifyPaymentsChild = _shopifyPaymentsChild;
const baseChild = _baseChild;

/**
 * Creates a DisputeFileUpload instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function DisputeFileUpload(shopify) {
  this.shopify = shopify;
  this.parentName = "disputes";
  this.name = "dispute_file_uploads";
  this.key = "dispute_file_upload";
}
assign(DisputeFileUpload.prototype, pick(baseChild, ["create", "delete"]));
DisputeFileUpload.prototype.buildUrl = shopifyPaymentsChild.buildUrl;
exports = DisputeFileUpload;
export default exports;