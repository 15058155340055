import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import * as _omit2 from "lodash/omit";
var _omit = _omit2;
try {
  if ("default" in _omit2) _omit = _omit2.default;
} catch (e) {}
import _baseChild from "../mixins/base-child";
import _base from "../mixins/base";
var exports = {};
const assign = _assign;
const omit = _omit;
const baseChild = _baseChild;
const base = _base;

/**
 * Creates a DiscountCode instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function DiscountCode(shopify) {
  this.shopify = shopify;
  this.parentName = "price_rules";
  this.name = "discount_codes";
  this.key = "discount_code";
}
assign(DiscountCode.prototype, omit(baseChild, "count"));

/**
 * Searches by discount code.
 *
 * @param {Object} params Query parameters
 * @return {Promise} Promise that resolves with the result
 * @public
 */
DiscountCode.prototype.lookup = function lookup(params) {
  const url = base.buildUrl.call(this, "lookup", params);
  return this.shopify.request(url, "GET", this.key);
};
exports = DiscountCode;
export default exports;