import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import _base from "../mixins/base";
var exports = {};
const assign = _assign;
const base = _base;

/**
 * Creates a CustomCollection instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function CustomCollection(shopify) {
  this.shopify = shopify;
  this.name = "custom_collections";
  this.key = "custom_collection";
}
assign(CustomCollection.prototype, base);
exports = CustomCollection;
export default exports;