import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import * as _omit2 from "lodash/omit";
var _omit = _omit2;
try {
  if ("default" in _omit2) _omit = _omit2.default;
} catch (e) {}
import _baseChild from "../mixins/base-child";
var exports = {};
const assign = _assign;
const omit = _omit;
const baseChild = _baseChild;

/**
 * Creates a GiftCardAdjustment instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function GiftCardAdjustment(shopify) {
  this.shopify = shopify;
  this.parentName = "gift_cards";
  this.name = "adjustments";
  this.key = "adjustment";
}
assign(GiftCardAdjustment.prototype, omit(baseChild, ["count", "delete", "update"]));
exports = GiftCardAdjustment;
export default exports;