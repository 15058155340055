import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import * as _pick2 from "lodash/pick";
var _pick = _pick2;
try {
  if ("default" in _pick2) _pick = _pick2.default;
} catch (e) {}
import _base from "../mixins/base";
var exports = {};
const assign = _assign;
const pick = _pick;
const base = _base;

/**
 * Creates a User instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function User(shopify) {
  this.shopify = shopify;
  this.name = "users";
  this.key = "user";
}
assign(User.prototype, pick(base, ["get", "list", "buildUrl"]));

/**
 * Gets the current logged-in user.
 *
 * @return {Promise} Promise that resolves with the result
 * @public
 */
User.prototype.current = function current() {
  return this.get("current");
};
exports = User;
export default exports;