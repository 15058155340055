import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import _base from "../mixins/base";
var exports = {};
const assign = _assign;
const base = _base;

/**
 * Creates a ScriptTag instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function ScriptTag(shopify) {
  this.shopify = shopify;
  this.name = "script_tags";
  this.key = "script_tag";
}
assign(ScriptTag.prototype, base);
exports = ScriptTag;
export default exports;