import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import * as _pick2 from "lodash/pick";
var _pick = _pick2;
try {
  if ("default" in _pick2) _pick = _pick2.default;
} catch (e) {}
import _baseChild from "../mixins/base-child";
var exports = {};
const assign = _assign;
const pick = _pick;
const baseChild = _baseChild;

/**
 * Creates a CancellationRequest instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function CancellationRequest(shopify) {
  this.shopify = shopify;
  this.parentName = "fulfillment_orders";
  this.key = this.name = "cancellation_request";
}
assign(CancellationRequest.prototype, pick(baseChild, "buildUrl"));

/**
 * Sends a cancellation request to the fulfillment service of a fulfillment
 * order.
 *
 * @params {Number} fulfillmentOrderId Fulfillment order ID
 * @params {String} [message] The reason reason for the cancellation request
 * @return {Promise} Promise that resolves with the result
 * @public
 */
CancellationRequest.prototype.create = function create(fulfillmentOrderId, message = "") {
  const url = this.buildUrl(fulfillmentOrderId);
  return this.shopify.request(url, "POST", undefined, {
    [this.key]: {
      message
    }
  }).then(body => body.fulfillment_order);
};

/**
 * Accepts a cancellation request sent to a fulfillment service for a
 * fulfillment order.
 *
 * @params {Number} fulfillmentOrderId Fulfillment order ID
 * @params {String} [message] The reason for accepting the cancellation request
 * @return {Promise} Promise that resolves with the result
 * @public
 */
CancellationRequest.prototype.accept = function accept(fulfillmentOrderId, message = "") {
  const url = this.buildUrl(fulfillmentOrderId, "accept");
  return this.shopify.request(url, "POST", undefined, {
    [this.key]: {
      message
    }
  }).then(body => body.fulfillment_order);
};

/**
 * Rejects a cancellation request sent to a fulfillment service for a
 * fulfillment order.
 *
 * @params {Number} fulfillmentOrderId Fulfillment order ID
 * @params {String} [message] The reason for rejecting the cancellation request
 * @return {Promise} Promise that resolves with the result
 * @public
 */
CancellationRequest.prototype.reject = function reject(fulfillmentOrderId, message = "") {
  const url = this.buildUrl(fulfillmentOrderId, "reject");
  return this.shopify.request(url, "POST", undefined, {
    [this.key]: {
      message
    }
  }).then(body => body.fulfillment_order);
};
exports = CancellationRequest;
export default exports;