import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import * as _pick2 from "lodash/pick";
var _pick = _pick2;
try {
  if ("default" in _pick2) _pick = _pick2.default;
} catch (e) {}
import _base from "../mixins/base";
var exports = {};
const assign = _assign;
const pick = _pick;
const base = _base;

/**
 * Creates a CollectionListing instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function CollectionListing(shopify) {
  this.shopify = shopify;
  this.name = "collection_listings";
  this.key = "collection_listing";
}
assign(CollectionListing.prototype, pick(base, ["get", "list", "buildUrl"]));

/**
 * Retrieves product IDs that are published to a particular collection.
 *
 * @param {Number} id Collection ID
 * @param {Object} [params] Query parameters
 * @return {Promise} Promise that resolves with the result
 * @public
 */
CollectionListing.prototype.productIds = function productIds(id, params) {
  const url = this.buildUrl(`${id}/product_ids`, params);
  return this.shopify.request(url, "GET", "product_ids");
};
exports = CollectionListing;
export default exports;