import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import * as _omit2 from "lodash/omit";
var _omit = _omit2;
try {
  if ("default" in _omit2) _omit = _omit2.default;
} catch (e) {}
import _base from "../mixins/base";
var exports = {};
const assign = _assign;
const omit = _omit;
const base = _base;

/**
 * Creates an ApplicationCharge instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function ApplicationCharge(shopify) {
  this.shopify = shopify;
  this.name = "application_charges";
  this.key = "application_charge";
}
assign(ApplicationCharge.prototype, omit(base, ["count", "delete", "update"]));

/**
 * Activates a previously accepted one-time application charge.
 *
 * @param {Number} id Application charge ID
 * @param {Object} params Application change properties
 * @return {Promise} Promise that resolves with the result
 * @public
 */
ApplicationCharge.prototype.activate = function activate(id, params) {
  const url = this.buildUrl(`${id}/activate`);
  return this.shopify.request(url, "POST", this.key, params);
};
exports = ApplicationCharge;
export default exports;