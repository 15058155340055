import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import * as _pick2 from "lodash/pick";
var _pick = _pick2;
try {
  if ("default" in _pick2) _pick = _pick2.default;
} catch (e) {}
import _base from "../mixins/base";
var exports = {};
const assign = _assign;
const pick = _pick;
const base = _base;

/**
 * Creates an ApiPermission instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function ApiPermission(shopify) {
  this.shopify = shopify;
  this.name = "api_permissions";
  this.key = "api_permission";
}
assign(ApiPermission.prototype, pick(base, "buildUrl"));

/**
 * Deletes a token, which revokes access.
 *
 * @return {Promise} Promise that resolves with the result
 * @public
 */
ApiPermission.prototype.delete = function remove() {
  const url = this.buildUrl("current");
  return this.shopify.request(url, "DELETE");
};
exports = ApiPermission;
export default exports;