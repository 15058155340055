import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import _base from "../mixins/base";
var exports = {};
const assign = _assign;
const base = _base;

/**
 * Creates a Webhook instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function Webhook(shopify) {
  this.shopify = shopify;
  this.name = "webhooks";
  this.key = "webhook";
}
assign(Webhook.prototype, base);
exports = Webhook;
export default exports;