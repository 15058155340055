import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import * as _pick2 from "lodash/pick";
var _pick = _pick2;
try {
  if ("default" in _pick2) _pick = _pick2.default;
} catch (e) {}
import _shopifyPayments from "../mixins/shopify-payments";
import _base from "../mixins/base";
var exports = {};
const assign = _assign;
const pick = _pick;
const shopifyPayments = _shopifyPayments;
const base = _base;

/**
 * Creates a Dispute instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function Dispute(shopify) {
  this.shopify = shopify;
  this.name = "disputes";
  this.key = "dispute";
}
assign(Dispute.prototype, pick(base, ["get", "list"]));
Dispute.prototype.buildUrl = shopifyPayments.buildUrl;
exports = Dispute;
export default exports;