import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import * as _pick2 from "lodash/pick";
var _pick = _pick2;
try {
  if ("default" in _pick2) _pick = _pick2.default;
} catch (e) {}
import _baseChild from "../mixins/base-child";
var exports = {};
const assign = _assign;
const pick = _pick;
const baseChild = _baseChild;

/**
 * Creates an Asset instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function Asset(shopify) {
  this.shopify = shopify;
  this.parentName = "themes";
  this.name = "assets";
  this.key = "asset";
}
assign(Asset.prototype, pick(baseChild, ["list", "buildUrl"]));

/**
 * Get a single asset by its ID.
 *
 * @param {Number} themeId Theme ID
 * @param {Object} params Query parameters
 * @return {Promise} Promise that resolves with the result
 * @public
 */
Asset.prototype.get = function get(themeId, params) {
  const url = this.buildUrl(themeId, undefined, params);
  return this.shopify.request(url, "GET", this.key);
};

/**
 * Creates an asset.
 *
 * @param {Number} themeId Theme ID
 * @param {Object} params Asset properties
 * @return {Promise} Promise that resolves with the result
 * @public
 */
Asset.prototype.create = function create(themeId, params) {
  const url = this.buildUrl(themeId);
  return this.shopify.request(url, "PUT", this.key, params);
};

/**
 * Updates an asset.
 *
 * @param {Number} themeId Theme ID
 * @param {Object} params Asset properties
 * @return {Promise} Promise that resolves with the result
 * @public
 */
Asset.prototype.update = Asset.prototype.create;

/**
 * Deletes an asset.
 *
 * @param {Number} themeId Theme ID
 * @param {Object} params Query parameters
 * @return {Promise} Promise that resolves with the result
 * @public
 */
Asset.prototype.delete = function remove(themeId, params) {
  const url = this.buildUrl(themeId, undefined, params);
  return this.shopify.request(url, "DELETE");
};
exports = Asset;
export default exports;