import * as _assign2 from "lodash/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import * as _omit2 from "lodash/omit";
var _omit = _omit2;
try {
  if ("default" in _omit2) _omit = _omit2.default;
} catch (e) {}
import _base from "../mixins/base";
var exports = {};
const assign = _assign;
const omit = _omit;
const base = _base;

/**
 * Creates a RecurringApplicationCharge instance.
 *
 * @param {Shopify} shopify Reference to the Shopify instance
 * @constructor
 * @public
 */
function RecurringApplicationCharge(shopify) {
  this.shopify = shopify;
  this.name = "recurring_application_charges";
  this.key = "recurring_application_charge";
}
assign(RecurringApplicationCharge.prototype, omit(base, ["count", "update"]));

/**
 * Activates a recurring application charge.
 *
 * @param {Number} id Recurring application charge ID
 * @param {Object} params Recurring application charge properties
 * @return {Promise} Promise that resolves with the result
 * @public
 */
RecurringApplicationCharge.prototype.activate = function activate(id, params) {
  const url = this.buildUrl(`${id}/activate`);
  return this.shopify.request(url, "POST", undefined, {
    [this.key]: params
  }).then(body => body[this.key]);
};

/**
 * Customize a recurring application charge.
 *
 * @param {Number} id Recurring application charge ID
 * @param {Object} params Customization parameters
 * @return {Promise} Promise that resolves with the result
 * @public
 */
RecurringApplicationCharge.prototype.customize = function customize(id, params) {
  const url = this.buildUrl(`${id}/customize`, {
    [this.key]: params
  });
  return this.shopify.request(url, "PUT", this.key);
};
exports = RecurringApplicationCharge;
export default exports;